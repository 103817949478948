export const API={
    list: "/operator/bookings",
}
export const COLUMNS= [
    {
        name: "reference",
        selector: "reference"
    },
    {
        name: "driver",
        selector: "driver"
    },
    {
        name: "departure_address",
        selector: "departure_address"
    },
    {
        name: "arrival_address",
        selector: "arrival_address"
    },
    {
        name: "passenger_firstname",
        selector: "passenger_firstname"
    },
    {
        name: "passenger_lastname",
        selector: "passenger_lastname"
    },
    {
        name: "passenger_phone",
        selector: "passenger_phone"
    },
    {
        name: "created_at",
        selector: "created_at"
    },
    {
        name: "status_code",
        selector: "status_code"
    },
    {
        name: "status_label",
        selector: "status_label"
    },
    {
        name: "licence",
        selector: "licence"
    },
    {
        name: "car_number",
        selector: "car_number"
    },
    {
        name: "registration_number",
        selector: "registration_number"
    },
    {
        name: "invoice_number",
        selector: "invoice_number"
    },
]
