import React, { Fragment, useState } from "react";
import FilterInput from "../Inputs";
import handleInputs from "../../helper/handleInputs";
import { Btn } from "../../AbstractElements";
import styles from "./reservation.module.css";

const Reservation = () => {
  const [inputs, setInputs] = useState({});
  const reservationItems = [
    {
      title: "Depart",
      placeholder: "depart",
      name: "depart",
      inputForm: "TitleInput",
    },
    {
      title: "Arival",
      placeholder: "arival",
      name: "arival",
      inputForm: "TitleInput",
    },
    {
      title: "Places",
      placeholder: "Places",
      name: "places",
      inputForm: "SelectInput",
      options: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
      ],
    },
  ];

  const passangerItems = [
    {
      title: "First name",
      placeholder: "first name",
      name: "first_name",
      inputForm: "TitleInput",
    },
    {
      title: "Last name",
      placeholder: "last name",
      name: "last_name",
      inputForm: "TitleInput",
    },
    {
      title: "Phone number",
      placeholder: "phone number",
      name: "phone_number",
      inputForm: "TitleInput",
    },
  ];

  const handleConvert = () => {
    console.log(inputs);
  };
  return (
    <Fragment>
      <div className={styles["form-container-reservation"]}>
        <div className={styles["reservation-label"]}>
          <h3>Reservation</h3>
        </div>
        {reservationItems.map((item, index) => (
          <FilterInput
            key={index}
            item={item}
            handleChange={(e) => handleInputs(e, setInputs)}
            index={index}
            className={styles["input-box-reservation"]}
          />
        ))}
        <div className={styles["reservation-label"]}>
          <h3>Passenger details</h3>
        </div>

        {passangerItems.map((item, index) => (
          <FilterInput
            key={index}
            item={item}
            handleChange={(e) => handleInputs(e, setInputs)}
            index={index}
          />
        ))}

        <Btn
          attrBtn={{
            onClick: handleConvert,
            color: "success",
          }}
        >
          Go
        </Btn>
      </div>
    </Fragment>
  );
};

export default Reservation;
