import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Footer, P } from "../../AbstractElements";

const FooterClass = () => {
  return (
    <Fragment>
      <Footer>
        <Container fluid={true}>
          <Row>
            <Col md="6" className="footer-copyright">
              <P attrPara={{ className: "mb-0" }}>
                © 2024 - Taxiora - operator
              </P>
            </Col>
            {/* <Col md="6">
              <P attrPara={{ className: "pull-right mb-0" }}></P>
            </Col> */}
          </Row>
        </Container>
      </Footer>
    </Fragment>
  );
};

export default FooterClass;
