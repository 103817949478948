import React, { Fragment, useEffect, useState, useContext } from "react";
import SidebarMenu from "./SidebarMenu";
import CustomizerContext from "../../_helper/Customizer";
import { MENUITEMS } from "./Menu";
import Profile from "./Profile";

const SideBarLayout = (props) => {
  const [currentUrl] = useState(window.location.pathname);
  const [width, setWidth] = useState(0);

  const { toggleIcon } = useContext(CustomizerContext);
  const id = window.location.pathname.split("/").pop();

  const layout = id;

  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  };

  const handleScroll = () => {
    if (window.scrollY > 400) {
      document.querySelector(".main-navbar").className = "main-navbar hovered";
    } else {
      if (document.getElementById("main-navbar"))
        document.querySelector(".main-navbar").className = "main-navbar";
    }
  };

  useEffect(() => {
    // setLeftArrow(true);
    window.addEventListener("resize", handleResize);
    handleResize();
    const currentUrl = window.location.pathname;

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [layout, currentUrl]);

  const closeOverlay = () => {
    document.getElementById("bg-overlay1").classList.remove("active");
    document.getElementById("nav-link").classList.remove("active");
  };

  return (
    <Fragment>
      <div
        id="bg-overlay1"
        onClick={() => {
          closeOverlay();
        }}></div>
      <header className={`main-nav ${toggleIcon ? "close_icon" : ""}`}>
        <Profile />
        <SidebarMenu props={props} sidebartoogle={true} width={width} />
      </header>
    </Fragment>
  );
};
export default SideBarLayout;
