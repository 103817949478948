import { Label, Row } from "reactstrap";
import React from "react";

import SelectApiInput from "./SelectApiInput";
import SimpleInput from "./SimpleInput";
import SelectInput from "./SelectInput";
import TitleInput from "./TitleInput";
import CheckBox from "./CheckBox";
import DateInput from "./DateInput";
import TextareaInput from "./TextareaInput";
import { useTranslation } from "react-i18next";
import MultiSelectApiInput from "./MultiSelectApiInput";
import Input from "./Input";

const FilterInput = ({ item, handleChange, oldValue }) => {
  const { t } = useTranslation();
  const formItem = {
    ...item,
    title: t(item.title),
    placeholder: t(item.placeholder),
  };

  switch (formItem.inputForm) {
    case "Input":
      return (
        <Input
          register={handleChange}
          inputName={formItem}
          oldValue={oldValue}
        />
      );
    case "SimpleInput":
      return (
        <SimpleInput
          register={handleChange}
          inputName={formItem}
          oldValue={oldValue}
        />
      );
    case "TitleInput":
      return (
        <TitleInput
          register={handleChange}
          inputName={formItem}
          oldValue={oldValue}
        />
      );
    case "SelectInput":
      return (
        <SelectInput
          register={handleChange}
          inputName={formItem}
          oldValue={oldValue}
        />
      );
    case "CheckBox":
      return (
        <CheckBox
          register={handleChange}
          inputName={formItem}
          oldValue={oldValue}
        />
      );
    case "SelectApiInput":
      return (
        <SelectApiInput
          register={handleChange}
          inputName={formItem}
          oldValue={oldValue}
        />
      );
    case "DateInput":
      return (
        <DateInput
          register={handleChange}
          inputName={formItem}
          oldValue={oldValue}
        />
      );
    case "TextareaInput":
      return (
        <TextareaInput
          register={handleChange}
          inputName={formItem}
          oldValue={oldValue}
        />
      );
    case "MultiSelectApiInput":
      return (
        <MultiSelectApiInput
          register={handleChange}
          inputName={formItem}
          oldValue={oldValue}
        />
      );
    default:
      return null;
  }
};

export default FilterInput;
