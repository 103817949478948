import { Fragment, useState } from "react";
import { Card, CardBody, Form, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SEND, SENDING } from "../../Constant";

import useFetchData from "../../hooks/useFetchData";
import handleInputs from "../../helper/handleInputs";

import Loading from "../Loading/Loading";
import FilterInput from "../Inputs";
import editData from "./editData";

import { toggleRefresh } from "../../redux/searchLink";
import { toggleModal } from "../../redux/modal";
import NotFound from "../NotFound";
import LoadingBtn from "../LoadingBtn";

function deleteNestedObjects(obj) {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      deleteNestedObjects(obj[key]);
      delete obj[key];
    }
  }
}

const EditItem = ({ itemsUpdate }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getLink, putLink } = useSelector((state) => state.currentRow);
  const { data, isLoading, isError } = useFetchData(getLink);
  const [isSending, setIsSending] = useState(false);
  const [inputs, setInputs] = useState({});
  let oldData = [];

  if (isLoading) return <Loading />;
  else {
    if (isError) return <NotFound />;

    try {
      oldData = data.data.results.data;
    } catch (error) {
      oldData = [];
    }

    deleteNestedObjects(oldData);
    console.log(oldData);

    const editedWithSuccess = () => {
      dispatch(toggleRefresh());
      dispatch(toggleModal());
    };

    const onSubmit = () => {
      const axiosUrl = putLink || getLink;
      editData(
        inputs,
        oldData,
        itemsUpdate,
        axiosUrl,
        setIsSending,
        editedWithSuccess
      );
    };

    return (
      <>
        <Card>
          <CardBody>
            <Form className="theme-form">
              <Row>
                {itemsUpdate.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <FilterInput
                        item={item}
                        handleChange={(e) => handleInputs(e, setInputs)}
                        oldValue={oldData[item.name]}
                      />
                    </Fragment>
                  );
                })}
              </Row>
            </Form>

            <LoadingBtn
              isLoading={isSending}
              content={{ done: t(SEND), loading: t(SENDING) }}
              attrBtn={{
                onClick: onSubmit,
                color: "success",
              }}
            />
          </CardBody>
        </Card>
      </>
    );
  }
};

export default EditItem;
