import React, { Fragment } from "react";
import { Card, CardBody } from "reactstrap";
import { useSelector } from "react-redux";

import DataTableHeader from "../../Components/DataTableHeader";
import DataTables from "../../Components/DataTables";
import { API, COLUMNS, EDIT_ITEMS } from "./data";
import generateSearchLink from "../../Services/generateSearchLink";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { USERS_PAGE } from "../../Constant";
import ModalApp from "../../Components/ModalApp";
import EditItem from "../../Components/EditItem";

const UsersPage = () => {
  const breadCrumbsProps = [USERS_PAGE];

  const { searchItem } = useSelector((state) => state.searchLink);
  const list = API.list;

  const searchLink = generateSearchLink(list, searchItem);

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <DataTableHeader createBtn={true} />
          <DataTables newColumns={COLUMNS} link={searchLink} />
        </CardBody>
      </Card>
      <ModalApp type="edit">
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default UsersPage;
