import { Card, CardBody } from "reactstrap";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { PASSENGER_COMMENTARY } from "../../Constant";
import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import generateSearchLink from "../../Services/generateSearchLink";

import Breadcrumbs from "../../Components/Breadcrumbs";
import ModalApp from "../../Components/ModalApp";
import EditItem from "../../Components/EditItem";
import EditBtn from "../../Components/DataTableBtns/EditBtn";
import AddItem from "../../Components/AddItem";
import DataTableHeader from "../../Components/DataTableHeader";
import DataTables from "../../Components/DataTables";

import useFetchData from "../../hooks/useFetchData";

const ReadIssuesPage = () => {
  const { code } = useParams();

  const { searchItem } = useSelector((state) => state.searchLink);

  const breadCrumbsProps = [PASSENGER_COMMENTARY];
  const list = API.list(code);
  const searchLink = generateSearchLink(list, searchItem);

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <DataTableHeader createBtn={true} />
          <DataTables newColumns={COLUMNS(code)} link={searchLink} />
        </CardBody>
      </Card>

      <ModalApp type="edit">
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>

      <ModalApp type="create">
        <AddItem addURL={API.create(code)} items={CREATE_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default ReadIssuesPage;
