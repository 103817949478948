import React from "react";

import Breadcrumbs from "../../Components/Breadcrumbs";
import HorizotalMenu from "../../Components/HorizotalMenu";
import PassengerDisputes from "./PassengerDisputes";
import DriverDisputes from "./DriverDisputes";

const IssuesPage = () => {
  const breadCrumbsProps = ["Disputes Page"];

  const compArray = [
    {
      title: "Passenger Disputes",
      comp: <PassengerDisputes />,
    },
    {
      title: "Driver Disputes",
      comp: <DriverDisputes />,
    },
  ];

  return (
    <>
      <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
      <HorizotalMenu props={compArray} />
    </>
  );
};

export default IssuesPage;
