import React, { useState } from "react";

import styles from "./ReservationPopUp.module.css";
import LoadingBtn from "../LoadingBtn";

const ReservationPopUp = () => {
  const [isSendingRes, setIsSendingRes] = useState(false);

  const handleReserve = () => {
    console.log("reserve");
    setIsSendingRes(true);

    setTimeout(() => {
      setIsSendingRes(false);
      console.log("Delayed for 1 second.");
    }, "1000");
  };

  return (
    <div>
      <div className={styles["popUp-Car-informations"]}>
        <div> Car Brand</div>
        <div className={styles["popUp-Car-registration"]}>
          <div className={styles["popUp-taxi-label"]}>Taxi :</div>

          <div className={styles["popUp-taxi"]}>B1234ABC</div>
        </div>
        <div className={styles["popUp-Car-registration"]}>
          <div className={styles["popUp-name-label"]}>Name:</div>
          <div className={styles["popUp-name"]}>Foulen ben Foulen</div>
        </div>
      </div>
      {/* <div className={styles["popUp-Status"]}>Status:</div> */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <LoadingBtn
          isLoading={isSendingRes}
          content={{ loading: "Sending", done: "Reserve" }}
          attrBtn={{
            onClick: handleReserve,
            color: "success",
            className: "me-4",
          }}
        />
      </div>
    </div>
  );
};

export default ReservationPopUp;
