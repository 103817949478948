import React from "react";
import { Btn } from "../../AbstractElements";

// import Axios from "axios";
import axiosClient from "../../Services/axiosClient";
import { changedAlert } from "../Alerts/Alerts";
import errorMsg from "../../Services/errorMsg";
import { toggleRefresh } from "../../redux/searchLink";
import { useDispatch } from "react-redux";
import { XCircle } from "react-feather";

const CloseBtn = ({ close: path, opened }) => {
  const dispatch = useDispatch();
  console.log(path);
  const handleClose = async () => {
    try {
      const response = await axiosClient.patch(path);
      if (response.status === 200) {
        console.log("Dispute closed successfully");
        changedAlert();
        dispatch(toggleRefresh());
      } else {
        console.error("Failed to close dispute");
      }
    } catch (error) {
      errorMsg(error);
      console.error("Error occurred while closing dispute:", error);
    }
  };

  return (
    <Btn
      attrBtn={{
        onClick: handleClose,
        color: "error",
        variant: "contained",
        className: "btn btn-danger btn-sm",
        disabled: !opened,
      }}
    >
      <XCircle />
    </Btn>
  );
};

export default CloseBtn;
