import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";

const Breadcrumbs = ({ breadCrumbsProps }) => {
  const { t } = useTranslation();
  const title = breadCrumbsProps[0];
  const elements = breadCrumbsProps.slice(1);
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col sm="6">
              <h3>{t(title)}</h3>
            </Col>
            <Col sm="6">
              <ol className="breadcrumb">
                {elements.map((elem, i) => (
                  <li className="breadcrumb-item" key={i}>
                    {t(elem)}
                  </li>
                ))}
              </ol>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
