import { createSlice } from "@reduxjs/toolkit";

export const searchLinkSlice = createSlice({
  name: "searchLink",

  initialState: {
    searchItem: {
      size: 10,
      index: 1,
      local: "fr",
    },
    refresh: false,
  },

  reducers: {
    handleChangeIndex: (state, action) => {
      state.searchItem.index = action.payload;
    },

    handleChangeSize: (state, action) => {
      state.searchItem.size = action.payload.size;
      state.searchItem.index = action.payload.index;
    },

    addSearchItems: (state, action) => {
      const result = { ...state.searchItem, ...action.payload };
      state.searchItem = result;
    },

    removeSearchItems: (state) => {
      const { local, index, size } = state.searchItem;
      state.searchItem = {
        size: size,
        index: index,
        local: local,
      };
    },

    initializeSearchItems: (state) => {
      state.searchItem = {
        size: 10,
        index: 1,
        local: "fr",
      };
    },

    toggleRefresh: (state) => {
      state.refresh = !state.refresh;
    },
  },
});

export const {
  handleChangeIndex,
  handleChangeSize,
  addSearchItems,
  initializeSearchItems,
  toggleRefresh,
  removeSearchItems,
} = searchLinkSlice.actions;

export default searchLinkSlice.reducer;
