import axiosClient from "../Services/axiosClient";
import { useEffect, useState } from "react";

const useFetchData = (url) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    axiosClient
      .get(url)
      .then((res) => {
        setData(res);
        setIsLoading(false);
        setIsError(false);
      })
      .catch((err) => {
        setData(err);
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  return { data, isLoading, isError };
};
export default useFetchData;
