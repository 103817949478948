import { Tool, Home } from "react-feather";

import {
  RESERVATION,
  OPERATIONAL,
  CONTACT_US,
  SETTINGS,
  ISSUES,
  USERS,
} from "../../Constant";

export const MENUITEMS = [
  {
    Items: [
      {
        path: "/home",
        icon: Home,
        type: "link",
        title: "Home",
      },
    ],
  },

  {
    menutitle: OPERATIONAL,
    Items: [
      {
        path: "/reservation",
        icon: Tool,
        type: "link",
        title: RESERVATION,
      },
      {
        path: "/issues",
        icon: Tool,
        type: "link",
        title: ISSUES,
      },
    ],
  },

  {
    menutitle: SETTINGS,
    Items: [
      {
        path: "/users",
        icon: Tool,
        type: "link",
        title: USERS,
      },
      {
        path: "/contact-us",
        icon: Tool,
        type: "link",
        title: CONTACT_US,
      },
    ],
  },
];
