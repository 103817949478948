import { Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#fbdc10" : "#ccc",
    boxShadow: state.isFocused ? "0 0 0 0.2rem #fbdc10" : "none",
  }),
};

const SelectInput = ({ register, inputName, oldValue }) => {
  const { name, title, options } = inputName;

  const handleOnChange = (e) => {
    register({ name: name, value: e.value });
  };
  const { t } = useTranslation();
  const translatedOptions = options.map((option) => ({
    label: t(option.label),
    value: option.value,
  }));
  return (
    <Col>
      <FormGroup>
        <Label>{title}</Label>
        <Select
          placeholder={oldValue}
          styles={customStyles}
          onChange={handleOnChange}
          options={translatedOptions}
          name={name}
        />
      </FormGroup>
    </Col>
  );
};
export default SelectInput;
