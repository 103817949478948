import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LI, UL, H6 } from "../../AbstractElements";
import { Label } from "reactstrap";
import { MENUITEMS } from "./Menu";
// import { initializeSearchItems } from "../../redux/searchLink";

const getCurrentActive = (path, array) => {};

const SidebarMenuItems = ({ sidebartoogle }) => {
  const pathName = window.location.pathname;

  // console.log(getCurrentActive(pathName, MENUITEMS));

  const [activeTitle, setActiveTitle] = useState("");
  const { t } = useTranslation();
  const toggletNavActive = (item) => {};

  const onChangePage = (prop) => {
    setActiveTitle(prop);
  };

  return (
    <Fragment>
      <UL attrUL={{ className: "nav-menu custom-scrollbar" }}>
        <LI attrLI={{ className: "back-btn" }}>
          <div className="mobile-back text-end">
            <span>Back</span>
            <i className="fa fa-angle-right ps-2"></i>
          </div>
        </LI>

        {MENUITEMS.map((Item, i) => (
          <Fragment key={i}>
            <LI attrLI={{ className: "sidebar-main-title" }}>
              <div>
                <H6>{t(Item.menutitle)}</H6>
              </div>
            </LI>
            {Item.Items.map((menuItem, i) => (
              <LI attrLI={{ className: "dropdown" }} key={i}>
                {menuItem.type === "sub" && (
                  <a
                    href="javascript"
                    id="nav-link"
                    className={`nav-link menu-title ${
                      activeTitle === menuItem.title ? "active" : ""
                    }`}
                    onClick={(event) => {
                      event.preventDefault();
                      onChangePage(menuItem.title);
                      // setNavActive(menuItem);
                    }}>
                    {menuItem.icon !== undefined && <menuItem.icon />}
                    <span>{t(menuItem.title)}</span>
                    <div className="according-menu">
                      {activeTitle === menuItem.title ? (
                        <i className="fa fa-angle-down"></i>
                      ) : (
                        <i className="fa fa-angle-right"></i>
                      )}
                    </div>
                  </a>
                )}
                {menuItem.type === "link" && (
                  <Link
                    to={menuItem.path}
                    id="nav-link"
                    className={`nav-link menu-title ${
                      activeTitle === menuItem.title ? "active" : ""
                    }`}
                    onClick={() => onChangePage(menuItem.title)}>
                    {menuItem.icon !== undefined && <menuItem.icon />}

                    <span>{t(menuItem.title)}</span>

                    {menuItem.badge && (
                      <Label className={menuItem.badge}>
                        {menuItem.badgetxt}
                      </Label>
                    )}
                  </Link>
                )}
                {menuItem.children && (
                  <UL
                    attrUL={{
                      className: "simple-list sidebar-submenu",
                    }}>
                    <UL
                      attrUL={{
                        className: "nav-submenu menu-content",
                        style:
                          activeTitle === menuItem.title
                            ? sidebartoogle
                              ? {
                                  opacity: 1,
                                  transition: "opacity 500ms ease-in",
                                }
                              : { display: "block" }
                            : { display: "none" },
                      }}>
                      {menuItem.children.map((childrenItem, index) => {
                        return (
                          <LI key={index}>
                            {childrenItem.type === "sub" && (
                              <a
                                href="javascript"
                                className={`${
                                  activeTitle === childrenItem.title
                                    ? "active"
                                    : ""
                                }`}
                                onClick={(event) => {
                                  event.preventDefault();
                                  onChangePage(childrenItem.title);
                                }}>
                                {t(childrenItem.title)}
                                <div className="according-menu">
                                  {activeTitle === childrenItem.title ? (
                                    <i className="fa fa-caret-down"></i>
                                  ) : (
                                    <i className="fa fa-caret-right"></i>
                                  )}{" "}
                                </div>
                              </a>
                            )}
                            {childrenItem.type === "link" && (
                              <Link
                                to={childrenItem.path}
                                className={`${
                                  activeTitle === childrenItem.title
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  toggletNavActive(childrenItem.title)
                                }>
                                {t(childrenItem.title)}
                              </Link>
                            )}
                            {childrenItem.children && (
                              <UL
                                attrUL={{
                                  className:
                                    "simple-list nav-sub-childmenu submenu-content",
                                  style:
                                    activeTitle === childrenItem.title
                                      ? { display: "block" }
                                      : { display: "none" },
                                }}>
                                {childrenItem.children.map(
                                  (childrenSubItem, key) => (
                                    <LI key={key}>
                                      {childrenSubItem.type === "link" && (
                                        <Link
                                          to={childrenSubItem.path}
                                          className={`${
                                            activeTitle ===
                                            childrenSubItem.title
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            toggletNavActive(childrenItem.title)
                                          }>
                                          {t(childrenSubItem.title)}
                                        </Link>
                                      )}
                                    </LI>
                                  )
                                )}
                              </UL>
                            )}
                          </LI>
                        );
                      })}
                    </UL>
                  </UL>
                )}
              </LI>
            ))}
          </Fragment>
        ))}
      </UL>
    </Fragment>
  );
};
export default SidebarMenuItems;
