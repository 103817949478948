import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Btn } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "../../redux/modal";

const ModalApp = (props) => {
  const { isOpen, modalType } = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const toggleModel = () => {
    dispatch(toggleModal());
  };

  return (
    <Modal
      isOpen={isOpen && modalType === props.type}
      toggle={toggleModel}
      size="xl"
      centered>
      <ModalHeader toggle={toggleModel}>{modalType}</ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      {/* <ModalFooter>
        <Btn attrBtn={{ color: "secondary", onClick: toggleModel }}>Close</Btn>
        <Btn attrBtn={{ color: "success", onClick: toggleModel }}>Save</Btn> 
      </ModalFooter> */}
    </Modal>
  );
};
export default ModalApp;
