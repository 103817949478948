import React from "react";
import Breadcrumbs from "../../Components/Breadcrumbs";
import HorizotalMenu from "../../Components/HorizotalMenu";

import OngoingReservation from "./OngoingReservation";
import HistoryReservation from "../ReservationPage/HistoryReservation";

const ReservationPage = () => {
  const breadCrumbsProps = ["Reservation"];

  const compArray = [
    {
      title: "Ongoing",
      comp: <OngoingReservation />,
    },
    {
      title: "History",
      comp: <HistoryReservation />,
    },
  ];

  return (
    <>
      <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
      <HorizotalMenu props={compArray} />
    </>
  );
};

export default ReservationPage;
