import ContactUsPage from "../pages/ContactUsPage";
import HomePage from "../pages/HomePage";
import IssuesPage from "../pages/IssuesPage";
import ReadIssuesPage from "../pages/ReadIssuesPage";
import ReservationPage from "../pages/ReservationPage";
import UsersPage from "../pages/UsersPage";

export const PAGE_ROUTES = [
  {
    path: "/home",
    element: <HomePage />,
  },

  // ------ Operational ------
  {
    path: "/reservation",
    element: <ReservationPage />,
  },

  {
    path: "/issues",
    element: <IssuesPage />,
  },

  {
    path: "/issues/:code",
    element: <ReadIssuesPage />,
  },

  // ------ Settings ------

  {
    path: "/users",
    element: <UsersPage />,
  },

  {
    path: "/contact-us",
    element: <ContactUsPage />,
  },
];
