export const API = {
    list : "/operator/disputes",
}
export const COLUMNS =[
    {
        name: "Pb Number",
        selector: "pb_number",
    },
    {
        name: "Passenger Firstname",
        selector: "passenger_firstname",
    },
    {
        name: "Passenger Lastname",
        selector: "passenger_lastname",
    },
    {
        name: "Passenger Phone",
        selector: "passenger_phone",
    },
    {
        name: "Passenger Active",
        selector: "passenger_active",
    },
    {
        name: "Booking Departure",
        selector: "booking_departure",
    },
    {
        name: "Booking Arrival",
        selector: "booking_arrival",
    },
    {
        name: "Passenger Id",
        selector: "passenger_id",
    },
    {
        name: "Booking Id",
        selector: "booking_id",
    },
    {
        name: "Created At",
        selector: "created_at",
    },
    {
        name: "Opened",
        selector: "opened",
    },
]
