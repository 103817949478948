import ActiveBtn from "../../Components/DataTableBtns/ActiveBtn";
import EditBtn from "../../Components/DataTableBtns/EditBtn";
import { civilityObj } from "../../Constant/objs";
export const API = {
  list: "/operator/users",
  patch_user: "/operator/user",
};

export const COLUMNS = [
  {
    name: "Civility",
    selector: "civility",
  },
  {
    name: "Firstname",
    selector: "firstname",
  },
  {
    name: "Lastname",
    selector: "lastname",
  },
  {
    name: "Phone",
    selector: "phone",
  },
  {
    name: "Role",
    selector: "role",
  },
  {
    name: "Mail",
    selector: "mail",
  },
  {
    name: "Created At",
    selector: "created_at",
  },

  {
    name: "Active",
    cell: (row) => (
      <ActiveBtn
        patch={API.patch_user + "/" + row.code + "/state"}
        state={row.active}
      />
    ),
    button: "true",
  },

  {
    name: "Edit",
    cell: (row) => (
      <EditBtn read={API.list} edit={API.patch_user + "/" + row.code} />
    ),
    button: "true",
  },
];
export const EDIT_ITEMS = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Phone Number",
    placeholder: "Phone Number",
    name: "phone",
    inputForm: "SimpleInput",
  },

  {
    title: "Address",
    placeholder: "Address",
    name: "address",
    inputForm: "SimpleInput",
  },
  {
    title: "City",
    placeholder: "city",
    name: "city",
    inputForm: "SimpleInput",
  },
  {
    title: "Zip code",
    placeholder: "zip code",
    name: "zip_code",
    inputForm: "SimpleInput",
  },
  civilityObj,
  {
    title: "active",
    name: "active",
    inputForm: "CheckBox",
  },
];
