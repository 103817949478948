export const activeObj = {
  name: "active",
  title: "Active",
  inputForm: "SelectInput",
  options: [
    {
      value: "",
      label: "all",
      name: "active",
    },
    {
      value: "true",
      label: "active",
      name: "active",
    },
    {
      value: "false",
      label: "not active",
      name: "active",
    },
  ],
};

export const civilityObj = {
  name: "civility",
  title: "Civility",
  inputForm: "SelectInput",
  options: [
    {
      value: "M",
      label: "Mr",
    },
    {
      value: "MME",
      label: "Mrs",
    },
  ],
};
