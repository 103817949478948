import Loader from "./Loader";
import Taptop from "./TapTop";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import React, { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import { Outlet } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const AppLayout = ({ children, classNames, ...rest }) => {
  const settings1 = "modern-sidebar";

  const sidebar_types1 = "compact-wrapper";

  const animationTheme = "fadeIn";

  return (
    <Fragment>
      <Loader />
      <Taptop />
      <div
        className={`page-wrapper ${sidebar_types1} ${settings1}`}
        id="pageWrapper">
        <Header />
        <div className="page-body-wrapper horizontal-menu">
          <Sidebar />
          <TransitionGroup {...rest}>
            <CSSTransition
              timeout={100}
              classNames={animationTheme}
              unmountOnExit>
              <div className="page-body">
                <Outlet />
              </div>
            </CSSTransition>
          </TransitionGroup>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};
export default AppLayout;
