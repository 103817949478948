import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "react-auth-kit";
import Routers from "./Route/index";

import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";

const App = () => {
  const queryClient = new QueryClient();

  document.documentElement.style.setProperty("--theme-default", "#ebca39");
  return (
    <div className="App">
      <CustomizerProvider>
        <AnimationThemeProvider>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider
                authType={"cookie"}
                authName={"_auth"}
                cookieDomain={window.location.hostname}
                cookieSecure={window.location.protocol === "https:"}>
                <Routers />
              </AuthProvider>
            </QueryClientProvider>
          </Provider>
        </AnimationThemeProvider>
      </CustomizerProvider>
    </div>
  );
};

export default App;
