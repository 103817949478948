import Swal from "sweetalert2";

export const addAlert = () => {
  Swal.fire({
    position: "top-end",
    icon: "success",
    title: "Your user has been created",
    showConfirmButton: false,
    timer: 1500,
  });
};

export const areYouSureAlert = () => {
  return Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, change it!",
  });
};

export const changedAlert = () => {
  Swal.fire({
    position: "top-end",
    icon: "success",
    title: "Your data has been changed",
    showConfirmButton: false,
    timer: 1500,
  });
};

export const ShowErrorMsg = (value) => {
  return Swal.fire({
    text: value,
    icon: "error",
  });
};

export const NotUnique = (parameter, value) => {
  return Swal.fire({
    title: value,
    text: parameter,
    icon: "warning",
  });
};

export const MissingParameters = (parameter, value) => {
  const missingParams = Object.keys(parameter);

  return Swal.fire({
    title: value + " " + parameter[missingParams[0]],
    text: missingParams,
    icon: "warning",
  });
};
