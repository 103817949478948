import L from "leaflet";
import React, { Fragment } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "./map.css";

import carIcon from "../../assets/images/map-icon/carPosition.png";
import ReservationPopUp from "../ReservationPopUp";

const MapOp = ({ listOfPositions }) => {
  const mapPosition = [36.8065, 10.1815];

  let DefaultIcon = new L.icon({
    iconUrl: carIcon,
    iconSize: [40, 35],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
  });
  L.Marker.prototype.options.icon = DefaultIcon;

  return (
    <div className="leaflet-container">
      <MapContainer center={mapPosition} zoom={10} scrollWheelZoom={true}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={mapPosition}>
          <Popup>
            <ReservationPopUp />
          </Popup>
        </Marker>

        {listOfPositions.map((elem, i) => (
          <Fragment key={i}>
            <Marker position={elem}>
              <Popup>
                <ReservationPopUp />
              </Popup>
            </Marker>
          </Fragment>
        ))}
      </MapContainer>
    </div>
  );
};

export default MapOp;
