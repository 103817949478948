import React from "react";
import { Spinner } from "reactstrap";
import { Btn } from "../../AbstractElements";

const LoadingBtn = ({ isLoading, content, attrBtn }) => {
  return (
    <Btn attrBtn={{ ...attrBtn, disabled: isLoading }}>
      {isLoading ? (
        <>
          <Spinner size="sm">Loading...</Spinner>
          <span> {content.loading}</span>
        </>
      ) : (
        <span> {content.done}</span>
      )}
    </Btn>
  );
};

export default LoadingBtn;
