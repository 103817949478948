import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import LoginForm from "./LoginForm";
import phoneImage from "../../assets/images/login/login.jpg";

const LoginPage = () => {
  const imageStyle = {
    backgroundImage: `url(${phoneImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
  };

  return (
    <Fragment>
      <section>
        <Container fluid={true}>
          <Row>
            <Col xl="5">
              <div style={imageStyle}></div>
            </Col>
            <Col xl="7 p-0">
              <LoginForm />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default LoginPage;
