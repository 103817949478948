export const DASHBOARD = "Dashboard";
export const POLYGONS = "Polygons";
export const PROFILES = "Profiles";
export const BRANDS = "Brands";
export const SERVICES_LIST = "Services list";
export const HOLIDAYS = "Holidays";
export const TAXIMETERS_IN_PREPARATION = "Taximeters in Preparation";
export const TAXIMETERS_IN_STOCKS = "Taximeters in Stocks";
export const BOXES = "Boxes";
export const IMPRIMANTES = "Imprimantes";
export const BADGE = "Badge";
export const CLIENT = "Client";
export const LIST_OF_USERS = "List of users";
export const SEARCH = "Search";
export const FILTERS = "Filters";
export const LOADING = "Loading";
export const PARAMETER = "Parameter";
export const STOCK = "Stock";
export const COMMERCIAL = "Commercial";
export const REFRESH = "Refresh";
export const QR_CODE = "QR Code";
export const IMPORT = "Import";
export const SEND = "Send";
export const SENDING = "Sending";
export const FINISHED_PRODUCTS_INVENTORY = "Finished Products Inventory";
export const FINISHED_PRODUCTS_STOCKS = "Finished Products Stocks";
export const WORDING = "Wording";
export const LOG_OUT = "Log Out";
export const FP_Inventory = "FP Inventory";
export const CREATE = "Create";
export const UPDATE = "Update";
export const IMPORT_DATA = "Import Data";
export const USERS_LIST = "List of Users";
export const USERS_PAGE = "Users";
export const POLYGONE = "Polygone";
export const POLYGONE_LIST = "List of Polygone";
export const POLYGONE_PAGE = "Polygone";
export const PROFILE = "Profile";
export const PROFILE_LIST = "List of Profile";
export const PROFILE_PAGE = "Profile";
export const BRAND = "Brand";
export const BRAND_LIST = "List of Brand";
export const BRAND_PAGE = "Brand";
export const SERVICES_LIST_LIST = "List of Services";
export const SERVICES_LIST_PAGE = "Services List";
export const TARIFF = "Tariff";
export const FLATFEE = "Flatfee";
export const PARAMETER_LIST = "List of Parameter";
export const TARIFF_LIST = "List of Tariff";
export const TAXIMETERS_IN_PREPARATION_LIST =
  "List of Taximeters in Preparation";
export const TAXIMETERS_IN_PREPARATION_PAGE = "Taximeters in Preparation";
export const TAXIMETERS_IN_STOCKS_LIST = "List of Taximeters in Stocks";
export const TAXIMETERS_IN_STOCKS_PAGE = "Taximeters in Stocks";
export const BOXES_LIST = "List of Boxes";
export const BOXES_PAGE = "Boxes";
export const IMPRIMANTES_LIST = "List of Imprimantes";
export const IMPRIMANTES_PAGE = "Imprimantes";
export const BADGE_LIST = "List of Badge";
export const BADGE_PAGE = "Badge";
export const CLIENT_LIST = "List of Client";
export const CLIENT_PAGE = "Client";
export const SENSOR = "Sensor";
export const SENSOR_LIST = "List of Sensor";
export const SENSOR_PAGE = "Sensor";
export const FIRMWARES = "Firmware";
export const FIRMWARE_LIST = "List of Firmware";
export const FIRMWARE_PAGE = "Firmware";
export const COLLABORATOR = "Collaborator";
export const PRODUCTION = "Production";
export const ADMINISTRATION = "Administration";
export const TAXIS = "Taxis";
export const BILLS = "Bills";
export const EDIT = "Edit";
export const WORK_ORDER = "Work order";

export const MATERIAL = "Material";
export const STATISTICS = "Statistics";

export const RAW_MATERIAL = "Raw Material";
export const FINISHED_PRODUCTS = "Finished Products";
export const INVENTORY = "Inventory";
export const STOCKS = "Stocks";
export const MODEL = "Model";
export const FAQ = "FAQ";
export const DISTRIBUTORS = "Distributors";
export const PURCHASE_ORDER = "Purchase order";
export const MY_PURCHASE_ORDER = "My purchase order";
export const SALES = "Sales";
export const SHRING = "Sharing";
export const TAXI = "Taxi";

export const PURCHASE_MY_ORDERS = "Purchase my orders";
export const MY_DISTRIBUTORS = "My Distributors";
export const MY_SENSOR = "My Sensors";
export const MY_STYLUS = "My Stylus";
export const MY_TAXIMETERS = "My Taximeters";
export const MY_BADGES = "My Badges";
export const MY_BOXES = "My Boxes";
export const MY_BRACKETS = "My Brackets";
export const MY_FAQ = "My FAQ";
export const MY_FIRMWARES = "My Firmwares";
export const MY_OBD_ADAPTERS = "My OBD Adapters";
export const MY_PRINTERS = "My Printers";
export const MY_SOS_BUTTONS = "My SOS Buttons";
export const MY_SUNSHIELDS = "My Sunshields";
export const MY_LUMINOUS = "My Luminous";
export const MY_USERS = "My Users";
export const MY_PARAMETER = "My parameter";
export const MY_RAW_MATERIAL_INVENTORY = "My Raw Material Inventory";
export const MY_RAW_MATERIAL_STOCKS = "My Raw Material Stocks";
export const MY_FINISHED_PRODUCTS_INVENTORY = "My Finished Products Inventory";
export const MY_FINISHED_PRODUCTS_STOCKS = "My Finished Products Stocks";
export const MY_POLYGONS = "My Polygons";
export const MY_WORDING = "My Wording";
export const MY_PROFILES = "My Profiles";
export const MY_BRANDS = "My Brands";
export const MY_SERVICES_LIST = "My Services List";
export const MY_HOLIDAYS = "My Holidays";
export const MY_MODEL = "My Model";
export const MY_CLIENT = "Client Page";
export const MY_PURCHASEORDER = "Purchase Order";
export const MY_STOCKS = "My Stocks";
export const MY_SALES = "My Sales";
export const MY_MARKETING_PASSAGER = "My Marketing Passager";
export const MY_RESERVATION = "My Reservation";
export const MY_SHARING = "My Sharing";
export const MY_IMPRIMANTES = "My Imprimantes";
export const MY_AGENCIES = "My Agencies";
export const MY_RULES = "My Rules";
export const MY_VALUES = "My Values";
export const MY_TARIFF = "My Tariff";
export const MY_FLATFEE = "My Flatfee";
export const MY_RELAY_SWITCHES = "My relay switches";
export const MY_FEMALE_BRACKET = "My female bracket";

export const MY_TAXIMETER_PRINTER = "My taximeter printer";
export const MY_TAXIMETER_LOCATIONS = "My taximeter location";
export const MY_TAXIMETER_BOXES = "My taximeter boxes";
export const MY_TAXIMETER_NOTEBOOK = "My taximeter notebook";
export const MY_TAXIMETER_TAXIS = "My taximeter taxis";

export const MY_COLLABORATORS = "My collaborators";
export const MY_COLLABORATOR = "My collaborator";

export const My_CLIENTS = "My clients";
export const My_CLIENT = "My client";
export const MY_TAXIS = "My taxis";
export const MY_BILLS = "My bills";

export const MY_WORK_ORDERS = "My work orders";
export const MY_WORK_ORDER = "My work order";


// --------------------------

export const SETTINGS = "Settings";
export const USERS = "Users";
export const CONTACT_US = "Contact us";
export const OPERATIONAL = "Operational";
export const RESERVATION = "Reservation";
export const ISSUES = "Issues";

export const PASSENGER_COMMENTARY = "Passenger Commentary";







