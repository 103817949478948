import EditBtn from "../../Components/DataTableBtns/EditBtn";

export const API = {
  list: (p) => `/operator/${p}/comments`,
  get: (p) => `/operator/${p}/comment`,
  create: (p) => `/operator/${p}/comment`,
};

export const COLUMNS = (d) => [
  {
    name: "Commentary",
    selector: "commentary",
  },
  {
    name: "Dispute id",
    selector: "dispute_id",
  },
  {
    name: "Problem Number",
    selector: "problem_number",
  },
  {
    name: "Problem Opened",
    selector: "problem_opened",
  },
  {
    name: "Created At",
    selector: "created_at",
  },
  {
    name: "Edit",
    cell: (row) => (
      <EditBtn
        read={`/operator/${d}/comments`}
        edit={`/operator/${d}/comment/${row.code}`}
      />
    ),
    button: "true",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Commentary",
    placeholder: "Edit Commentary",
    name: "commentary",
    inputForm: "TextareaInput",
  },
];

export const CREATE_ITEMS = [
  {
    title: "Commentary",
    placeholder: "Add Commentary",
    name: "commentary",
    inputForm: "TextareaInput",
  },
];
