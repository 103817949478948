import React, { Fragment } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import { H6, Image } from "../../AbstractElements";
import { Settings } from "react-feather";

const Profile = () => {
  const { getData } = useLocalStorage();
  const userData = getData("userData");

  const defaultUserData = require("../../Constant/userImg.json");

  const currentUser = userData
    ? {
        fullName: `${userData.firstname} ${userData.lastname}`,
        img: userData.img,
        role: userData.role,
      }
    : {
        fullName: `${defaultUserData.firstname} ${defaultUserData.lastname}`,
        img: defaultUserData.img,
        role: defaultUserData.role,
      };

  return (
    <Fragment>
      <div className="sidebar-user text-center">
        <div className="setting-primary">
          <Settings />
        </div>
        {/* <Link to={`${process.env.PUBLIC_URL}/app/users/userProfile`}> */}
        <Image
          attrImage={{
            className: "img-90 rounded-circle",
            src: currentUser.img,
            //   src: authenticated ? auth0_profile.picture : profile,
            alt: "",
          }}
        />
        <div className="badge-bottom">
          {/* <div className="badge badge-primary" style={{ color: "black" }}>
            {currentUser.role}
          </div> */}
          <div className="badge badge-primary">{currentUser.role}</div>
        </div>
        <H6
          attrH6={{
            className: "mt-3 f-14 f-w-600",
          }}
        >
          {currentUser.fullName}
        </H6>
        {/* </Link> */}
      </div>
    </Fragment>
  );
};

export default Profile;
