import React, { Fragment } from "react";
import DataTables from "../../../Components/DataTables";
import { Card, CardBody } from "reactstrap";
import DataTableHeader from "../../../Components/DataTableHeader";
import { API, COLUMNS } from "./data";
import { useSelector } from "react-redux";
import generateSearchLink from "../../../Services/generateSearchLink";

const DriverDisputes = () => {
  const { searchItem } = useSelector((state) => state.searchLink);

  const list = API.list;
  const searchLink = generateSearchLink(list, searchItem);

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <DataTableHeader createBtn={true} />
          <DataTables newColumns={COLUMNS} link={searchLink} />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default DriverDisputes;
