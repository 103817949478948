import CloseBtn from "../../../Components/DataTableBtns/CloseBtn";
import ReadPageBtn from "../../../Components/DataTableBtns/ReadPageBtn";

export const API = {
  list: "/operator/disputes",
  get: "/operator/dispute",
  addComment: "/operator",
};

export const COLUMNS = [
  {
    name: "Pb number",
    selector: "pb_number",
  },
  {
    name: "Passenger Firstname",
    selector: "passenger_firstname",
  },
  {
    name: "Passenger Lastname",
    selector: "passenger_lastname",
  },
  {
    name: "Passenger Phone",
    selector: "passenger_phone",
  },
  {
    name: "Booking Departure",
    selector: "booking_departure",
  },
  {
    name: "Booking Arrival",
    selector: "booking_arrival",
  },
  {
    name: "Created At",
    selector: "created_at",
  },
  {
    name: "Opened",
    selector: "opened",
  },

  {
    name: "Read",
    cell: (row) => <ReadPageBtn read={"/issues/" + row.code} />,
    button: "true",
  },

  {
    name: "Close Dispute",
    cell: (row) => (
      <CloseBtn close={API.get + "/" + row.code} opened={row.opened} />
    ),
    button: "true",
  },
];
