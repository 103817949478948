import axios from "axios";
import sha512 from "js-sha512";
// import { useSignIn } from 'react-auth-kit';

const apiKey = process.env.REACT_APP_URL_API;

export const login = async ({ username, password }) => {
  // const signIn = useSignIn();
  const url = apiKey + "/authenticate";
  const intention = "ADMIN_OPERATOR";

  const hashPass = sha512(password);

  const authData = {
    username: username,
    intention: intention,
    password: hashPass,
  };

  let result = null;

  try {
    result = await axios.post(url, authData);
  } catch (err) {
    console.error(err);
    result = err;
  }

  return result;
};
