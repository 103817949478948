import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import generateSearchLink from "../../../Services/generateSearchLink";
import { Card, CardBody } from "reactstrap";
import DataTableHeader from "../../../Components/DataTableHeader";
import DataTables from "../../../Components/DataTables";
import { API, columns } from "./data";

const OngoingReservation = () => {
  const { searchItem } = useSelector((state) => state.searchLink);

  const list = API.list;
  const searchLink = generateSearchLink(list, searchItem);
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <DataTableHeader />
          <DataTables newColumns={columns} link={searchLink} />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default OngoingReservation;
