import { Col, FormGroup, Label } from "reactstrap";
import React, { Fragment } from "react";

const Input = ({ register, inputName, oldValue }) => {
  const { name, type, title, placeholder } = inputName;

  const handleOnChange = (e) => {
    register(e);
  };

  return (
    <Fragment>
      <Col>
        <FormGroup>
          <Label>{title}</Label>
          <input
            className="form-control input-style"
            placeholder={placeholder}
            onChange={handleOnChange}
            defaultValue={oldValue}
            type={type}
            name={name}
            required
          />
        </FormGroup>
      </Col>
    </Fragment>
  );
};

export default Input;
