import React, { useState } from "react";
import MapOp from "../../Components/MapOp";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { Col, Row } from "reactstrap";
import FilterInput from "../../Components/Inputs";
import Reservation from "../../Components/Reservation";
import { taxis } from "./data";
// import ConnectToSocket from "./ConnectToSocket";
// import { webSocket } from "../../Services/webSocket";

const HomePage = () => {
  const breadCrumbsProps = ["Home"];

  return (
    <>
      <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
      {/* <ConnectToSocket /> */}
      <Row className="mb-4">
        <Col xs={9}>
          <MapOp listOfPositions={taxis} />
        </Col>
        <Col xs={3}>
          <Reservation />
        </Col>
      </Row>
    </>
  );
};

export default HomePage;
