import { createSlice } from "@reduxjs/toolkit";

export const selectedRowsSlice = createSlice({
  name: "selectedRows",
  initialState: {
    listOfCodes: [],
    selectAll: false,
  },

  reducers: {
    addCodes: (state, action) => {
      const codeToAdd = action.payload;
      const isExist = state.listOfCodes.includes(codeToAdd);
      if (isExist) {
        state.listOfCodes = state.listOfCodes.filter(
          (code) => code !== codeToAdd
        );
      } else {
        state.listOfCodes.push(codeToAdd);
      }
    },

    toggleSelectAll: (state) => {
      state.selectAll = !state.selectAll;
      if (state.selectAll) state.listOfCodes = [];
    },

    initializeListOfCodes: (state) => {
      state.listOfCodes = [];
    },
  },
});

export const { addCodes, initializeListOfCodes, deleteCode, toggleSelectAll } =
  selectedRowsSlice.actions;

export default selectedRowsSlice.reducer;
