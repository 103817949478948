import { Col, FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import React, { Fragment } from "react";
import { useState } from "react";
import { Calendar } from "react-feather";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const DateInput = ({ register, inputName, oldValue }) => {
  // const initialDate = new Date();
  const [selectedDate, setSelectedDate] = useState(null);

  const { name, title } = inputName;

  const handleChange = (date) => {
    setSelectedDate(date);
    const newDate = moment(date).format("YYYY-MM-DD");
    const res = { name: name, value: newDate };
    register(res);
  };

  return (
    <Fragment>
      <Col sm="3">
        <FormGroup>
          <Label>{title}</Label>
          <div>
            <DatePicker
              placeholderText={oldValue}
              showIcon
              className="form-control datetimepicker-input digits input-style"
              selected={selectedDate}
              onChange={handleChange}
              dateFormat="d MMMM , yyyy "
              icon={<Calendar />}
            />
          </div>
        </FormGroup>
      </Col>
    </Fragment>
  );
};

export default DateInput;
