export const API = {
  list: "/operator/bookings",
};
export const columns = [
  { name: "Reference", selector: "reference" },
  //   { name: "Driver", selector: "driver" },
  //   { name: "Departure Address", selector: "start_position.title" },
  //   { name: "Arrival Address", selector: "arrival_position.street" },
  //   { name: "Passenger Name", selector: "" },
  //   { name: "Created At", selector: "createdAt" },
  //   { name: "Status", selector: "status" },
  // { name: "vehicle type", selector: "vehicle_type" },
  // { name: "type direction", selector: "typeDirection" },
];
