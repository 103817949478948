import { Card, CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../Loading/Loading";
import NotFound from "../NotFound";

import {
  handleChangeIndex,
  handleChangeSize,
  initializeSearchItems,
} from "../../redux/searchLink";
import getRowsFromRes from "../../helper/getRowsFromRes";

import axiosClient from "../../Services/axiosClient";

const DataTables = ({ newColumns, link }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);

  const { refresh } = useSelector((state) => state.searchLink);

  const dispatch = useDispatch();
  const columns = transformColumns(newColumns);

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(link)
      .then((res) => {
        setData(res);
        setIsLoading(false);
        setIsError(false);
      })
      .catch((err) => {
        setData([]);
        setIsLoading(false);
        setIsError(true);
        console.error(err);
      });
  }, [link, refresh]);

  const { total: paginationTotalRows, rows } = getRowsFromRes(data);

  const handlePerRowsChange = (newPerPage, page) => {
    const payload = { index: page, size: newPerPage };
    dispatch(handleChangeSize(payload));
  };

  const handlePageChange = (page) => {
    dispatch(handleChangeIndex(page));
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All list",
  };

  if (isError) return <NotFound />;

  return (
    <Fragment>
      <Card>
        <CardBody>
          <DataTable
            columns={columns}
            progressPending={isLoading}
            progressComponent={
              <div style={{ marginBottom: "100px" }}>
                <Loading />
              </div>
            }
            data={rows}
            striped="true"
            center="true"
            persistTableHead
            pagination
            paginationServer
            paginationTotalRows={paginationTotalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationComponentOptions={paginationComponentOptions}
            onChangePage={handlePageChange}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default DataTables;

function transformColumns(columns) {
  if (!columns) return [];

  return columns.map((column) => {
    if (column.button) return column;
    const transformedColumn = {
      name: column.name,
      // selector: (row) => row[column.selector],
      selector: (row) => {
        const cellData = row[column.selector];
        if (cellData === true) return "True";
        if (cellData === false) return "False";
        if (cellData === null) return "Null";
        return cellData;
      },
      sortable: "true",
      center: "true",
    };
    return transformedColumn;
  });
}
