import React, { Fragment, useState } from "react";
import styles from "./HorizotalMenu.module.css";
import { Col, Row } from "reactstrap";

const HorizotalMenu = ({ props }) => {
  const [selectedItem, setSelectedItem] = useState(0);

  const handleTabClick = (tab) => {
    setSelectedItem(tab);
  };

  return (
    <Fragment>
      <Row>
        {props.map((elem, i) => {
          const affiche = selectedItem === i ? true : false;
          return (
            <Fragment key={i}>
              <Col>
                <div className={styles["navTab-reservation"]}>
                  <div
                    className={`${styles["navItem-reservation"]} ${
                      affiche ? styles.active : ""
                    }`}
                    onClick={() => handleTabClick(i)}
                  >
                    {elem.title}
                  </div>
                </div>
              </Col>
            </Fragment>
          );
        })}
      </Row>
      <pre> {props[selectedItem].comp} </pre>
    </Fragment>
  );
};

export default HorizotalMenu;
