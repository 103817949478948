import { useState, Fragment } from "react";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";

import FilterInput from "../Inputs";
import postData from "./postData";

import handleInputs from "../../helper/handleInputs";
import { SEND, SENDING } from "../../Constant";
import { useTranslation } from "react-i18next";
import { toggleRefresh } from "../../redux/searchLink";
import { toggleModal } from "../../redux/modal";
import { useDispatch } from "react-redux";
import LoadingBtn from "../LoadingBtn";

const AddItem = ({ addURL, items }) => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const addedWithSuccess = () => {
    dispatch(toggleRefresh());
    dispatch(toggleModal());
  };

  const onSubmit = () => {
    setIsLoading(true);
    postData(inputs, addURL, setIsLoading, addedWithSuccess);
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form className="theme-form">
                <Row>
                  {items.map((item, index) => (
                    <FilterInput
                      key={index}
                      item={item}
                      handleChange={(e) => handleInputs(e, setInputs)}
                      index={index}
                    />
                  ))}
                </Row>
                <LoadingBtn
                  isLoading={isLoading}
                  content={{ done: t(SEND), loading: t(SENDING) }}
                  attrBtn={{
                    onClick: onSubmit,
                    color: "success",
                  }}
                />
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default AddItem;
